<template>
  <v-card height="100%" width="100%" flat>
    <v-card color="transparent" v-if="splash" flat>
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8" xl="6">
          <v-card outlined class="rounded-xl mb-5">
            <v-toolbar dark flat color="secondary">
              <v-toolbar-title>
                <v-icon class="mr-2">mdi-alert</v-icon>
                Identification Issue
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <h3>
                The email address or phone number you've used to login is not
                currently associated with an Osteon account.
              </h3>
              <p class="mt-5">
                If you'd like to try again with a different email address or
                phone number, please <a @click="logout">click here</a>.
              </p>
            </v-card-text>
          </v-card>
          <v-card color="transparent" flat>
            <v-card-text class="px-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-card outlined class="rounded-xl mb-5">
                    <v-toolbar dark flat color="primary">
                      <v-toolbar-title>
                        <v-icon class="mr-2">mdi-account-plus</v-icon>
                        Register New Account
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <v-card
                        color="success"
                        max-width="150px"
                        class="rounded-xl mx-auto"
                        @click="splash = false"
                      >
                        <v-card-text class="text-center white--text">
                          <v-icon dark size="64">mdi-account</v-icon>
                          <h4>Register Now</h4>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card outlined class="rounded-xl mb-5">
                    <v-card-text>
                      <p>
                        If you believe you're seeing this message in error,
                        please contact our support team to rectify this issue.
                      </p>
                      <h5 class="mb-1">Australia</h5>
                      <h3 class="mb-5">
                        <v-icon class="mr-2">mdi-phone</v-icon>
                        <a href="tel:0392640111">(03) 9264 0111</a>
                      </h3>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card flat v-if="!splash">
      <v-row justify="center">
        <v-col cols="12" md="10" lg="8" xl="6">
          <v-card outlined class="rounded-xl pb-2">
            <v-toolbar dark flat color="primary">
              <v-toolbar-title>
                <v-icon class="mr-2">mdi-account</v-icon>
                Register for an Account
              </v-toolbar-title>
            </v-toolbar>
            <v-row v-if="loading">
              <v-col class="text-center">
                <v-progress-circular
                  indeterminate
                  size="128"
                  color="primary"
                  class="my-5"
                >
                  Submitting
                </v-progress-circular>
              </v-col>
            </v-row>
            <v-card-text v-if="!loading && !submitted">
              <v-form ref="registerForm" lazy-validation>
                <v-row class="mt-5">
                  <v-col cols="12" md="6" class="py-1">
                    <h4>
                      <v-icon class="ma-2">mdi-account</v-icon>
                      Your Name
                    </h4>
                    <v-text-field
                      v-model="name"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Your Name"
                      clearable
                      rounded
                    />
                    <h4>
                      <v-icon class="ma-2">mdi-email</v-icon>
                      Email
                    </h4>
                    <v-text-field
                      v-model="email"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Email"
                      type="email"
                      clearable
                      rounded
                    />
                    <h4>
                      <v-icon class="ma-2">mdi-phone</v-icon>
                      Phone Number
                    </h4>
                    <v-text-field
                      v-model="phoneNumber"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Phone Number"
                      type="number"
                      clearable
                      rounded
                    />
                    <h4>
                      <v-icon class="ma-2">mdi-hospital</v-icon>
                      Practice Name
                    </h4>
                    <v-text-field
                      v-model="practiceName"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Practice Name"
                      clearable
                      rounded
                    />
                  </v-col>
                  <v-col cols="12" md="6" class="py-1">
                    <h4>
                      <v-icon class="ma-2">mdi-road-variant</v-icon>
                      Address
                    </h4>
                    <v-text-field
                      v-model="address"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Address"
                      clearable
                      rounded
                    />
                    <h4>
                      <v-icon class="ma-2">mdi-home-group</v-icon>
                      Suburb
                    </h4>
                    <v-text-field
                      v-model="suburb"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Suburb"
                      clearable
                      rounded
                    />
                    <h4>
                      <v-icon class="ma-2">mdi-map</v-icon>
                      State
                    </h4>
                    <v-text-field
                      v-model="state"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="State"
                      clearable
                      rounded
                    />
                    <h4>
                      <v-icon class="ma-2">mdi-post</v-icon>
                      Post code / Zip code
                    </h4>
                    <v-text-field
                      v-model="postcode"
                      :rules="[(value) => !!value || 'Required']"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Post code / Zip code"
                      clearable
                      rounded
                    />
                    <h4>
                      <v-icon class="ma-2">mdi-earth</v-icon>
                      Country / Region
                    </h4>
                    <v-autocomplete
                      v-model="country"
                      :rules="[(value) => !!value || 'Required']"
                      :items="countryItems"
                      :menu-props="{ rounded: 'xl' }"
                      dense
                      outlined
                      light
                      background-color="white"
                      placeholder="Country / Region"
                      clearable
                      rounded
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-text v-if="!loading && submitted">
              <v-row>
                <v-col cols="12" class="text-center my-5">
                  <v-icon size="96" color="success"
                    >mdi-check-circle-outline</v-icon
                  >
                  <h2 class="success--text mt-5">Thank you for Registering</h2>
                </v-col>
                <v-col cols="12" class="text-center my-5">
                  <h2 class="mb-5">
                    We will notify you via email once your account has been
                    activated.
                  </h2>
                  <p>
                    One of our customer service representatives will review your
                    application and contact you if they have any additional
                    questions.
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="ma-2" v-if="!loading && !submitted">
              <v-spacer />
              <v-btn large rounded color="success" @click="submitForm()">
                <v-icon class="ma-2">mdi-account-plus</v-icon>
                Register
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import options from "@/plugins/axios/axios_options";

export default {
  name: "ClientRegistration",
  data() {
    return {
      splash: true,
      loading: false,
      submitted: false,
      countries: [],
      name: null,
      email: null,
      phoneNumber: null,
      practiceName: null,
      address: null,
      suburb: null,
      state: null,
      postcode: null,
      country: null,
    };
  },
  watch: {
    clients(value) {
      if (value.length > 0) {
        this.$router.push({ name: "homePage" });
      }
    },
  },
  computed: {
    ...mapGetters(["clients"]),
    countryItems() {
      return this.countries.map((country) => {
        return {
          text: country.country,
          value: country.code,
        };
      });
    },
  },
  methods: {
    logout() {
      this.$axios.defaults.headers.Authorization = null;
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));
      window.location.href = options.logoutURL;
    },
    submitForm() {
      if (!this.$refs.registerForm.validate()) {
        return;
      }
      this.loading = true;
      this.submitted = false;
      this.$axios
        .post("/ordering/order_forms/client/register/", {
          payload: {
            name: this.name,
            email: this.email,
            phone_number: this.phoneNumber,
            practice_name: this.practiceName,
            address: this.address,
            suburb: this.suburb,
            state: this.state,
            postcode: this.postcode,
            country: this.country,
          },
        })
        .then(() => {
          this.loading = false;
          this.submitted = true;
        });
    },
    async fetchCountries() {
      this.$axios
        .get("/ordering/order_forms/client/country_list/")
        .then((response) => {
          this.countries = response.data;
        });
    },
  },
  mounted() {
    delete this.$axios.defaults.headers.Authorization;
    this.fetchCountries();
  },
};
</script>
