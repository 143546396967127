<template>
  <v-card outlined class="mb-5 rounded-xl">
    <v-toolbar dark flat color="primary">
      <v-toolbar-title>
        <v-icon class="mr-2">mdi-hexagon-multiple</v-icon>
        Case {{ collection.collection_id }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12" xl="6">
          <p>
            <v-icon class="mr-2">mdi-hospital</v-icon>
            {{ collection.client_name }}
          </p>
          <p>
            <v-icon class="mr-2">mdi-account</v-icon>
            {{ collection.patient_reference }}
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CaseHeader",
  computed: {
    ...mapGetters(["collection"]),
  },
};
</script>
