<template>
  <v-expand-transition>
    <v-bottom-navigation
      v-if="collection && show && smallScreen"
      height="64"
      light
      app
      grow
      color="primary"
    >
      <v-btn class="rounded-xl" icon @click="navigateHome()">
        <span>Back</span>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        class="rounded-xl"
        value="overview"
        icon
        :to="{ name: 'caseOverview', params: { uid: collection.uid } }"
      >
        <span>Overview</span>
        <v-badge overlap color="warning" :value="openApprovals.length > 0">
          <template v-slot:badge>
            <small>{{ openApprovals.length }}</small>
          </template>
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-badge>
      </v-btn>
      <v-btn class="rounded-xl" value="chat" icon :to="{ name: 'caseChat' }">
        <span>Chat</span>
        <v-badge overlap color="primary" :value="unreadMessage > 0">
          <template v-slot:badge>
            <small>{{ unreadMessage }}</small>
          </template>
          <v-icon>mdi-message</v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        class="rounded-xl"
        value="order_forms"
        icon
        :to="{ name: 'caseOrderForms' }"
      >
        <span>Order Form</span>
        <v-icon>mdi-clipboard-text</v-icon>
      </v-btn>
      <v-btn
        class="rounded-xl"
        value="order_files"
        icon
        :to="{ name: 'caseOrderFiles' }"
      >
        <span>Files</span>
        <v-icon>mdi-rotate-3d</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <v-navigation-drawer
      v-if="collection && show && !smallScreen"
      app
      clipped
      color="transparent"
      class="pt-3 px-3"
      width="300"
    >
      <v-card outlined class="rounded-xl">
        <v-card-text>
          <v-list rounded dense>
            <v-list-item @click="navigateHome()">
              <v-list-item-avatar size="16">
                <v-icon small color="primary"> mdi-arrow-left </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'black--text': $route.name === 'homePage' }"
                >
                  Back
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader>Case Navigator</v-subheader>
            <v-list-item
              active-class="white"
              :to="{ name: 'caseOverview', params: { uid: collection.uid } }"
            >
              <v-list-item-avatar size="16">
                <v-icon small color="primary">
                  mdi-format-list-bulleted
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'black--text': $route.name === 'caseOverview' }"
                >
                  Case Overview
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="white"
              :to="{ name: 'caseChat', params: { uid: collection.uid } }"
            >
              <v-list-item-avatar size="16">
                <v-icon small color="primary"> mdi-message </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'black--text': $route.name === 'caseChat' }"
                >
                  Chat
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="white"
              :to="{ name: 'caseOrderForms', params: { uid: collection.uid } }"
            >
              <v-list-item-avatar size="16">
                <v-icon small color="primary"> mdi-clipboard-text </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'black--text': $route.name === 'caseOrderForms' }"
                >
                  Order Form
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              active-class="white"
              :to="{ name: 'caseOrderFiles', params: { uid: collection.uid } }"
            >
              <v-list-item-avatar size="16">
                <v-icon small color="primary"> mdi-rotate-3d </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  :class="{ 'black--text': $route.name === 'caseOrderFiles' }"
                >
                  Files
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-expand-transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CaseNavigator",
  watch: {
    $route() {
      this.caseNavigatorController();
    },
  },
  computed: {
    ...mapGetters(["show", "collection", "openApprovals"]),
    unreadMessage() {
      if (!this.collection) {
        return 0;
      }
      if (!this.collection.messages) {
        return 0;
      }
      return this.collection.messages.filter((message) => !message.message_seen)
        .length;
    },
    smallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
  },
  methods: {
    ...mapActions(["showCaseNavigation", "hideCaseNavigation"]),
    navigateHome() {
      this.$router.push({ name: "homePage" });
    },

    caseNavigatorController() {
      if (
        this.$route.name === "caseOverview" ||
        this.$route.name === "caseChat" ||
        this.$route.name === "caseOrderForms" ||
        this.$route.name === "caseOrderFiles"
      ) {
        this.showCaseNavigation();
      } else {
        this.hideCaseNavigation();
      }
    },
  },

  mounted() {
    this.caseNavigatorController();
  },
};
</script>

<style scoped>
.v-navigation-drawer >>> .v-navigation-drawer__border {
  display: none;
}
</style>
