<template>
  <v-app>
    <v-app-bar app clipped-left clipped-right color="primary">
      <v-app-bar-nav-icon v-if="currentClient">
        <v-btn dark icon @click="drawer = !drawer">
          <v-icon large>mdi-menu</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <v-app-bar-title>
        <v-img
          contain
          width="45"
          :src="require('@/assets/osteon-logo.png')"
          @click="navigateHome()"
          style="cursor: pointer"
        />
      </v-app-bar-title>
    </v-app-bar>
    <NavigationDrawer v-if="currentClient" v-model="drawer" />
    <CaseNavigator />
    <v-main>
      <v-container fluid :class="containerClass">
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>
html {
  overflow-y: auto;
}
</style>

<script>
import fetchURLParam from "@/shared/url_params";
import apiOptions from "@/plugins/axios/axios_options";
import { mapGetters, mapActions } from "vuex";
import CaseNavigator from "@/components/CaseNavigator";
import NavigationDrawer from "@/components/NavigationDrawer";

export default {
  name: "App",
  components: {
    CaseNavigator,
    NavigationDrawer,
  },
  data() {
    return {
      fullscreen: false,
      drawer: false,
      notRegistered: false,
    };
  },
  watch: {
    $route(to) {
      this.fullscreen = false;
      if (!this.notRegistered) {
        return;
      }
      if (to.name === "clientRegistration") {
        return;
      }
      this.$router.push({ name: "clientRegistration" });
    },
    currentClient(value) {
      if (!value) {
        return;
      }
      this.fetchPracticeData();
    },
    reloadTrigger(value) {
      if (!value) {
        return;
      }
      this.fetchPracticeData().then(() => {
        this.reloadComplete();
      });
    },
  },
  computed: {
    ...mapGetters(["isLoggedIn", "reloadTrigger", "clients", "currentClient"]),
    containerClass() {
      if (this.fullscreen) {
        return "fill-height pa-0";
      }
      return "fill-height";
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions([
      "setAccessToken",
      "reloadComplete",
      "setClients",
      "setCurrentClient",
      "setActiveCases",
      "setArchivedCases",
      "setApprovalRequiredCases",
      "setIncompleteSubmissions",
      "setInternationalToothConvention",
    ]),
    initializeApplication(accessToken) {
      this.setAccessToken(accessToken);
      this.$axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
      this.$axios
        .get("/ordering/clients/")
        .then((response) => {
          this.setClients(response.data);
          if (this.clients.length > 1) {
            const currentClientUID = this.$cookies.get(
              "osteonCurrentClientUID"
            );
            if (currentClientUID) {
              const client = this.clients.find(
                (client) => client.uid === currentClientUID
              );
              if (client) {
                this.setCurrentClient(client);
              }
            }
          }
          if (!this.currentClient) {
            this.$router.push({ name: "practiceSelector" });
          } else if (this.$route.name === "authCallback") {
            this.$router.push({ name: "homePage" });
          }
        })
        .catch(() => {
          if (this.$route.name !== "authCallback") {
            this.$axios.defaults.headers.Authorization = null;
            this.$cookies
              .keys()
              .forEach((cookie) => this.$cookies.remove(cookie));
            return this.redirectToID();
          }
          this.notRegistered = true;
          if (this.$route.name === "clientRegistration") {
            return;
          }
          this.$router.push({ name: "clientRegistration" });
        });
    },
    redirectToID() {
      window.location = `${apiOptions.authURL}?client_id=${
        apiOptions.clientID
      }&response_type=token&redirect_uri=${encodeURIComponent(
        apiOptions.redirectURI
      )}`;
    },
    navigateHome() {
      if (this.$route.name === "homePage") {
        return;
      }
      this.$router.push({ name: "homePage" });
    },
    changePractice() {
      this.$router.push({ name: "practiceSelector" });
    },
    async fetchPracticeData() {
      return new Promise((resolve) => {
        const requests = [
          this.fetchApprovalRequiredCases(),
          this.fetchActiveCases(),
          this.fetchArchivedCases(),
          this.fetchSubmissions(),
        ];
        Promise.all(requests).then(() => resolve());
      });
    },
    async fetchActiveCases() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/${this.currentClient.uid}/active/`, {
            params: { platform_tag: "oep" },
          })
          .then((response) => {
            this.setActiveCases(response.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    async fetchArchivedCases() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/${this.currentClient.uid}/archived/`, {
            params: { platform_tag: "oep" },
          })
          .then((response) => {
            this.setArchivedCases(response.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    async fetchApprovalRequiredCases() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/${this.currentClient.uid}/approvals/`, {
            params: { platform_tag: "oep" },
          })
          .then((response) => {
            this.setApprovalRequiredCases(response.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    async fetchSubmissions() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/ordering/submissions/${this.currentClient.uid}/`, {
            params: { platform_tag: "oep" },
          })
          .then((response) => {
            this.setIncompleteSubmissions(response.data);
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
  },
  beforeMount() {
    let accessToken = this.$cookies.get("osteonAccessToken");
    if (accessToken) {
      this.initializeApplication(accessToken);
    } else {
      accessToken = fetchURLParam("access_token");
      const expiresInSecs = fetchURLParam("expires_in");
      const expiresInMSecs = expiresInSecs * 1000;
      const expiresAt = new Date(new Date().getTime() + expiresInMSecs);
      if (accessToken && expiresAt) {
        this.$cookies.config(expiresAt);
        this.$cookies.set("osteonAccessToken", accessToken);
        this.initializeApplication(accessToken);
      } else {
        this.redirectToID();
      }
    }
  },
  mounted() {
    const existingConvention = this.$cookies.get(
      "internationalToothConvention"
    );
    if (existingConvention) {
      this.setInternationalToothConvention(existingConvention);
    }
  },
};
</script>
