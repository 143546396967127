<template>
  <v-expansion-panels accordion class="rounded-xl" flat>
    <v-expansion-panel
      v-for="(kase, index) in cases"
      :key="index"
      style="border: thin solid rgba(0, 0, 0, 0.12)"
    >
      <v-expansion-panel-header style="border-radius: inherit">
        <v-row>
          <v-col cols="12">
            {{ kase.job_type }}
          </v-col>
          <v-col cols="12" class="text-right">
            <Status :kase="kase" class="mr-2" />
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content eager>
        <ExpandedDetail :kase="kase" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import Status from "./StatusChip";
import ExpandedDetail from "./ExpandedDetail";

export default {
  name: "CaseComponents",
  props: ["cases"],
  components: {
    Status,
    ExpandedDetail,
  },
};
</script>

<style scoped>
.v-expansion-panel {
  -webkit-transform: translate3d(0, 0, 0);
}
</style>
